import React, { FunctionComponent, PropsWithChildren } from 'react';
import { BaseButtonProps, compileButtonClasses } from './button';
import classNames from 'classnames';
import styles from './button.module.scss';
import { Link } from '../../visitor-react/visitor-react';


export type LinkButtonProps = BaseButtonProps & {
  href: string;
  title?: string;
};

export const LinkButton: FunctionComponent<PropsWithChildren<LinkButtonProps>> = ({
                                                                                    className,
                                                                                    children,
                                                                                    kind = 'primary',
                                                                                    loading = false,
                                                                                    fullWidth = false,
                                                                                    ...props
                                                                                  }) => {

  const classes = compileButtonClasses({
    className,
    kind,
    fullWidth,
  });

  return (
    <Link className={classes} {...props}>
      <span className={classNames(styles.content, { [styles.invisible]: loading })}>
        {children}
      </span>
    </Link>
  );
};
