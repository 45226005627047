import { Navbar } from "./base/navbar/navbar";
import styles from "./base.module.scss";
import { CallToAction } from "../components/call-to-action";
import Footer from "./base/footer";
import ContactForm from "@feature/contact/components/contact-form";
import Partners from "@feature/partners/components/partners";
import Faq from "@feature/faq/components/faq";
import { useShared } from "../../visitor-react/visitor-react";

import "swiper/css";
import "aos/dist/aos.css";

export const base = ({ children }) => {
  const shared = useShared();

  return (
    <>
      <Navbar />
      <CallToAction />
      <main className={styles.base}>{children}</main>
      <ContactForm />
      <Partners partners={shared?.partners} />
      <Faq entries={shared?.faq} />
      <Footer />
    </>
  );
};
