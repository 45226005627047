import styles from './faq.module.scss';
import type { FaqEntry } from '@models/faq-entry';
import ImgPng from '../../../../../../public/static/faq/faq.png';
import ImgWebp from '../../../../../../public/static/faq/faq.webp';
import { SectionWrapper } from '../../../layouts/wrappers';
import SectionTitle from '../../typography/section-title';
import React from 'react';
import { BlockRenderer } from '../../../components/blocks';
import Paragraph from '../../typography/paragraph';
import { LinkButton } from '../../../components/link-button';
import { route } from '../../../../routing/main';
import { Collapse } from '../../../components/collapse';
import { EMPTY_IMAGE } from '@feature/shared/helpers/empty-image';

type FaqSectionType = {
  entries: FaqEntry[]
}
export default function Faq({ entries }: FaqSectionType) {
  if (!entries || entries.length === 0) {
    return null;
  }

  return (
    <section className={styles.section}>
      <SectionWrapper as={SectionTitle} className={styles.sectionTitle} data-aos="fade-up">
        Najczęściej <span>zadawane pytania</span>
      </SectionWrapper>
      <SectionWrapper as="div" className={styles.container}>
        <div className={styles.column}>
          <picture>
            <source srcSet={ImgWebp} type="image/webp" media="(max-width: 769px)"/>
            <source srcSet={ImgPng} type="image/png" media="(max-width: 769px)"/>
            <source srcSet={EMPTY_IMAGE} type="image/png" media="(max-width: 768px)"/>
            <img src={ImgPng} alt="Faq" loading="lazy"/>
          </picture>
        </div>
        <div className={styles.column}>
          {entries.map((element, index) => (
            <Collapse key={index} open={index === 0} title={
              <div className={styles.title}>{element.title}</div>
            }>
              {element.description && (
                <BlockRenderer data={element.description} renderers={{
                  paragraph: <Paragraph size={10}/>,
                }}/>
              )}
            </Collapse>
          ))}
        </div>
      </SectionWrapper>

      {entries.length > 3 && (
        <SectionWrapper className={styles.buttonWrapper}>
          <LinkButton href={route('faq_module')}>Zobacz wszystkie</LinkButton>
        </SectionWrapper>
      )}
    </section>
  );
}
