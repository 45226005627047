import React, {FunctionComponent, useState, useEffect} from "react";
import {ReactComponent as Logo} from "../../../assets/company-logo.svg";
import {ReactComponent as Phone} from "../../../assets/phone.svg";
import {ReactComponent as Email} from "../../../assets/email.svg";
import {ReactComponent as Whatsup} from "../../../assets/whats-up.svg";
import {ReactComponent as Messanger} from "../../../assets/messanger.svg";
import {ReactComponent as Instagram} from "../../../assets/instagram.svg";
import {ReactComponent as Facebook} from "../../../assets/facebook.svg";
import {Link, useShared, useLocation} from "../../../../visitor-react/visitor-react";
import {route} from "../../../../routing/main";

import styles from "./navbar.module.scss";
import {NavbarItem} from "./navbar-item";
import {Burger} from "./burger";
import classNames from "classnames";

export type NavbarProps = {};

export const Navbar: FunctionComponent<NavbarProps> = () => {
    const shared = useShared();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showSocials, setShowSocials] = useState<boolean>(false);
    const location = useLocation();

    const toggleMenu = () => {
        if (window) {
            if (window.innerWidth < 601) {
                // There are duplacted svgs on page, and two of them have mask with ID, so browser gets stupid and doesn't show elements ( duplacted ID's)
                setShowSocials(true);
            } else {
                setShowSocials(false);
            }
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    return (
        <nav className={styles.navbar} id="navbar">
            <div className={styles.content}>
                <Link href={route("homepage_module")} className={styles.logo} title="Centrum Bruku">
                    <Logo/>
                </Link>

                <ul className={classNames(styles.menu, {[styles.open]: isOpen})}>
                    {shared?.menu.menu_top &&
                        shared?.menu.menu_top.map((element, index) => (
                            <NavbarItem item={element} key={index}/>
                        ))}

                    {showSocials && (
                        <li className={styles.socials}>
                            {shared?.contact.whatsup && (
                                <a href={shared?.contact.whatsup} title="Whatsup">
                                    <Whatsup/>
                                </a>
                            )}
                            {shared?.contact.messanger && (
                                <a href={shared?.contact.messanger} title="Messanger">
                                    <Messanger id="test"/>
                                </a>
                            )}
                            {shared?.contact.instagram && (
                                <a href={shared?.contact.instagram} title="Instagram">
                                    <Instagram/>
                                </a>
                            )}
                            {shared?.contact.facebook && (
                                <a href={shared?.contact.facebook} title="Facebook">
                                    <Facebook/>
                                </a>
                            )}
                        </li>
                    )}
                </ul>

                <div className={styles.contact}>
                    <a href={`mailto:${shared?.contact.email}`} title="Email">
                        <Email/>
                    </a>
                    <a href={`tel:${shared?.contact.phone}`} className={styles.phone} title="Telefon">
                        <Phone/>
                        {/*<span>{shared?.contact.phone}</span>*/}
                    </a>
                </div>
                <div className={styles.socialsWrapper}>
                    <div className={styles.socials}>
                        {shared?.contact.whatsup && (
                            <a href={shared?.contact.whatsup} title="Whatsup">
                                <Whatsup/>
                            </a>
                        )}
                        {shared?.contact.messanger && (
                            <a href={shared?.contact.messanger} title="Messanger">
                                <Messanger/>
                            </a>
                        )}
                        {shared?.contact.instagram && (
                            <a href={shared?.contact.instagram} title="Instagram">
                                <Instagram/>
                            </a>
                        )}
                        {shared?.contact.facebook && (
                            <a href={shared?.contact.facebook} title="Facebook">
                                <Facebook/>
                            </a>
                        )}
                    </div>
                </div>
                <Link href={"/fundusze-europejskie"} className={styles.funds}>
                    <img src={"/static/logo-ue.png"} alt="Fundusze Europejskie"/>
                </Link>

                <Burger toggle={toggleMenu} active={isOpen}/>
            </div>
        </nav>
    );
};
