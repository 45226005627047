import { Fragment, DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';

type SizeType = {
  size: string;
  media?: {
    min?: number;
    max?: number;
  }
}

type Photoable = {
  photos: Photo[];
}

type Photo = {
  filename: string;
  mime: string;
  type: string;
  alt: string | null;
}

type BaseProps = {
  config: string,
  sizes: SizeType[],
  alt?: string;
  loading?: 'lazy' | 'eager';
  fallback?: string;
}

type WithPhoto = { photo: Photo; };
type WithPhotoable = { photoable: Photoable; type: string; };

type PictureProps = DetailedHTMLProps<HTMLAttributes<HTMLPictureElement>, HTMLPictureElement>;
type PropsWithPhoto = PictureProps & BaseProps & WithPhoto;
type PropsWithPhotoable = PictureProps & BaseProps & WithPhotoable;
type Props = PropsWithPhoto | PropsWithPhotoable;

export function Picture({ config, sizes, alt = '', loading = 'lazy', fallback, ...props }: Props): ReactElement<any, any> | null {
  const { photo, picture } = extractProps(props);

  if (!photo) {
    if (fallback) {
      return (
        <picture {...picture}>
          <img src={fallback} alt={alt} loading={loading}/>
        </picture>
      );
    }

    return (
      <picture {...picture}>
        <img src={photoUrl(config, sizes[0]?.size)} alt={alt} loading={loading}/>
      </picture>
    );
  }

  return (
    <picture {...picture}>
      {sizes.map(size => (
        <Fragment key={size.size}>
          <source srcSet={photoUrl(config, size.size, photo.filename, true)} media={mediaQuery(size)} type="image/webp"/>
          <source srcSet={photoUrl(config, size.size, photo.filename)} media={mediaQuery(size)} type={photo.mime || 'image/png'}/>
        </Fragment>
      ))}
      <img src={photoUrl(config, undefined, photo.filename)} alt={photo.alt || alt} loading={loading}/>
    </picture>
  );
}


type ExtractableWithPhotoable = PictureProps & WithPhotoable;
type ExtractableWithPhoto = PictureProps & WithPhoto;
type Extractable = ExtractableWithPhotoable | ExtractableWithPhoto;

function extractProps(props: Extractable) {
  if ('photo' in props) {
    const { photo, ...picture } = props;

    return { photo, picture };
  }

  const { photoable, type, ...picture } = props;
  const photo = extractPhoto(type, photoable);

  return { photo, picture };
}


export function photoUrl(config: string, size?: string, filename?: string, webp: boolean = false) {
  if (config.includes('/')) {
    config = config.split('/').shift() as string;
  }

  let path = `/photos/${config}`;

  if (size) {
    path = `${path}/${size}`;
  }

  if (filename) {
    if (webp) {
      filename = filename.substring(0, filename.lastIndexOf('.')) + '.webp';
    }

    path = `${path}/${filename}`;
  }

  return path;
}


export function mediaQuery(size: SizeType): string | undefined {
  let min = size.media?.min;
  let max = size.media?.max;

  if (!min && !max) {
    return undefined;
  } else if (min && max) {
    return `(min-width: ${min}px) and (max-width: ${max}px)`;
  } else if (min) {
    return `(min-width: ${min}px)`;
  }

  return `(max-width: ${max}px)`;
}


export function extractPhoto(type: string, model: Photoable): Photo | undefined;
export function extractPhoto(type: string, model: Photoable, multi: true): Photo[];
export function extractPhoto(type: string, model: Photoable, multi?: true) {
  if (multi) {
    return model.photos.filter((photo: Photo) => photo.type === type);
  } else {
    return model.photos.find((photo: Photo) => photo.type === type);
  }
}
