import { createElement } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { createVisitor } from '../visitor-react/visitor-react';
import app from './app';
import { base } from './layouts/base';

createVisitor({
  resolve: async (name: string) => {
    const components = import.meta.glob(`./views/**/*.tsx`, { eager: false });
    const component: any = await components[`./views/${name}.tsx`]();

    component.default.layout = component.default.layout || base;

    return component;
  },

  setup: (options) => {
    if (import.meta.hot) {
      createRoot(document.getElementById('app') as HTMLElement).render(createElement(app, options));
    } else {
      hydrateRoot(document.getElementById('app') as HTMLElement, createElement(app, options));
    }
  },
});
