"use client";

import ModalContent from "@feature/modal/components/modal-content";
import ModalHeader from "@feature/modal/components/modal-header";
import { PropsWithChildren } from "react";
import { Form } from "react-final-form";
import { GridRow } from "../../../components/grid";
import { BaseModalProps, Modal } from "@feature/modal/modal";
import useCaptchaButton from "../hooks/use-captcha-button";
import { useShared } from "../../../../visitor-react/visitor-react";
import { FieldInput } from "@feature/form/fields/field-input";
import { FieldCheckbox } from "@feature/form/fields/field-checkbox";
import { FieldTextarea } from "@feature/form/fields/field-textarea";

type Props = PropsWithChildren<BaseModalProps> & {
  onSubmit: (values: Object, api: any) => void;
  submitting: boolean;
  errors: any;
};

export default function QuickContactModal({ isOpen, onClose, onSubmit, submitting, errors }: Props) {
  const { loadCaptcha, CaptchaButton, setLoadCaptcha } = useCaptchaButton();
  const shared = useShared();

  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} onFocus={() => setLoadCaptcha(true)}>
      <ModalContent>
        <ModalHeader title={"Zapytaj o wycenę"} />

        <Form
          onSubmit={onSubmit}
          initialValues={{
            rodo: false,
            token: "",
            text: "",
            name: "",
            page: (window && window.location.pathname) || "",
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate tabIndex={0}>
              <FieldInput type="hidden" name="token" />
              <GridRow>
                <FieldInput label="Imię" name="name" required error={errors?.name} />
              </GridRow>
                <GridRow>
                <FieldInput label="Nazwisko" name="surname" required error={errors?.name} />
              </GridRow>
              <GridRow>
                <FieldInput label="Email" name="email" type="email" required error={errors?.email}/>
              </GridRow>
              <GridRow>
                <FieldInput label="Telefon" name="phone" />
              </GridRow>
              <GridRow>
                <FieldTextarea label="Wiadomość" name="text" />
              </GridRow>
              <GridRow>
                <FieldCheckbox name="rodo" required>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez Gawbud Łukasz Gawlik z
                  siedzibą w 39-200 Dębica ul. Krucza 7 (dalej: „CentrumBruku”) zawartych w
                  formularzu kontaktowym w celu realizacji przesłanego za jego pośrednictwem
                  zgłoszenia. Zapoznałem/zapoznałam się z klauzulą informacyjną. Jestem
                  świadom/świadoma, iż moja zgoda może być odwołana w każdym czasie poprzez wysłanie
                  maila na adres:
                  <a href={`mailto:${shared?.contact.email}`}>{shared?.contact.email}</a>, co
                  skutkować będzie usunięciem moich danych osobowych przez CentrumBruku.
                </FieldCheckbox>
              </GridRow>
              <GridRow>
                <CaptchaButton disabled={!loadCaptcha || submitting}>Wyślij</CaptchaButton>
              </GridRow>
            </form>
          )}
        </Form>
      </ModalContent>
    </Modal>
  );
}
